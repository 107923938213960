import _defineProperty from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from "../hooks";
import { ApplicationModal, setModalState, setOpenModal } from "./reducer";
export var useBlockNumber = () => {
  var _useWeb3React = useWeb3React(),
      chainId = _useWeb3React.chainId;

  return useAppSelector(state => state.application.blockNumber[chainId]);
};
export var useMainnetBlockNumber = () => {
  return useAppSelector(state => state.application.blockNumber[1]);
};
export var useModalOpened = modal => {
  var modalOpened = useAppSelector(state => state.application.openModal);
  return modalOpened.includes(modal);
};
export var useOneOfModalOpened = modals => {
  var modalOpened = useAppSelector(state => state.application.openModal);
  return modalOpened.some(modal => modals.includes(modal));
};
export var useModalToggle = (modal, opt) => {
  var _ref = opt || {
    stack: false,
    modalState: null
  },
      stack = _ref.stack,
      modalState = _ref.modalState;

  var dispatch = useAppDispatch();
  var opened = useModalOpened(modal);
  var modalOpened = useAppSelector(state => state.application.openModal);
  return useCallback(() => {
    dispatch(setOpenModal(opened ? modalOpened.filter(m => m !== modal) : stack ? [...modalOpened, modal] : [modal]));
    dispatch(setModalState({
      modal,
      state: modalState
    }));
  }, [dispatch, modal, opened, modalState, stack, modalOpened]);
};
export var useModalSwitch = modal => {
  var dispatch = useAppDispatch();
  var opened = useModalOpened(modal);
  var modalOpened = useAppSelector(state => state.application.openModal);
  return useCallback((open, opt) => {
    // opened
    if (open === opened) return;

    var _ref2 = opt || {
      stack: false,
      modalState: null
    },
        stack = _ref2.stack,
        modalState = _ref2.modalState;

    if (stack) {
      dispatch(setOpenModal(open ? [...modalOpened, modal] : modalOpened.filter(m => m !== modal)));
    } else {
      dispatch(setOpenModal(open ? [modal] : []));
    }

    dispatch(setModalState({
      modal,
      state: modalState
    }));
  }, [dispatch, modal, opened]);
};
export var useModalState = modal => {
  return useAppSelector(state => {
    var _state$application$mo;

    return (_state$application$mo = state.application.modalState[modal]) !== null && _state$application$mo !== void 0 ? _state$application$mo : {};
  });
};
export var useApprovalModalStateDispatch = () => {
  var dispatch = useAppDispatch();
  return useCallback(_ref3 => {
    var progress = _ref3.progress,
        hash = _ref3.hash,
        description1 = _ref3.description1,
        description2 = _ref3.description2;
    dispatch(setModalState({
      modal: ApplicationModal.APPROVAL_STATUS,
      state: _objectSpread(_objectSpread({
        progress,
        hash
      }, description1 && {
        description1
      }), description2 && {
        description2
      })
    }));
  }, [dispatch]);
};
export var useRefreshToken = () => {
  return useAppSelector(state => state.application.refreshToken);
};
export var useFeeData = () => {
  var _useWeb3React2 = useWeb3React(),
      chainId = _useWeb3React2.chainId;

  return useAppSelector(state => state.application.feeData[chainId]);
};