import { TokenlonEnv } from "../../constants/env";
import LogoETH from "../../assets/images/chain-eth.svg";
import LogoArb from "../../assets/images/chain-arbitrum.svg";
import { LOCALES } from "../../locales";
var DEFAULT_RETRY_OPTIONS = {
  n: 10,
  minWait: 250,
  maxWait: 1000
};
export var Mainnet = {
  chainId: 1,
  chainName: 'Ethereum',
  logoUrl: LogoETH,
  nativeSymbol: 'ETH',
  rpcUrl: 'https://eth-mainnet.token.im',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  lonAddress: '0x0000000000095413afC295d19EDeb1Ad7B71c952',
  xLonAddress: '0xf88506b0f1d30056b9e5580668d5875b9cd30f23',
  rewardDistributorAddress: '0xbF1C2c17CC77e7Dec3466B96F46f93c09f02aB07',
  explorer: 'https://etherscan.io',
  getExplorerAddressLink: (address, locale) => "https://".concat(locale === LOCALES.ZH ? 'cn.etherscan.com' : 'etherscan.io', "/address/").concat(address),
  getExplorerTransactionLink: (transactionHash, locale) => "https://".concat(locale === LOCALES.ZH ? 'cn.etherscan.com' : 'etherscan.io', "/tx/").concat(transactionHash),
  getExplorerTokenLink: (address, locale) => "https://".concat(locale === LOCALES.ZH ? 'cn.etherscan.com' : 'etherscan.io', "/token/").concat(address),
  matchedEnv: [TokenlonEnv.production, TokenlonEnv.staging]
};
export var Goerli = {
  chainId: 5,
  chainName: 'Goerli',
  nativeSymbol: 'ETH',
  logoUrl: LogoETH,
  rpcUrl: 'https://eth-testnet.imdev.works',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  lonAddress: '0x6dA0e6ABd44175f50C563cd8b860DD988A7C3433',
  xLonAddress: '0x235d9B4249E9C9D705fAC6E98F7D21E58091220A',
  rewardDistributorAddress: '0x02F929c7C837839a0D6B44B1d6Dbce64Fa87C9f3',
  explorer: 'https://goerli.etherscan.io',
  getExplorerAddressLink: address => "https://goerli.etherscan.io/address/".concat(address),
  getExplorerTransactionLink: transactionHash => "https://goerli.etherscan.io/tx/".concat(transactionHash),
  getExplorerTokenLink: address => "https://goerli.etherscan.io/token/".concat(address),
  matchedEnv: [TokenlonEnv.local, TokenlonEnv.development]
};
var SepoliaExplorer = 'https://sepolia.etherscan.io';
export var Sepolia = {
  chainId: 11155111,
  chainName: 'Sepolia',
  nativeSymbol: 'ETH',
  logoUrl: LogoETH,
  rpcUrl: 'https://sepolia-testnet.imdev.works',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0xD7F33bCdb21b359c8ee6F0251d30E94832baAd07',
  lonAddress: '0x6C1851b852F05bdc7c0BE1A088532E4999fD94Fa',
  explorer: SepoliaExplorer,
  getExplorerAddressLink: address => "".concat(SepoliaExplorer, "/address/").concat(address),
  getExplorerTransactionLink: transactionHash => "".concat(SepoliaExplorer, "/tx/").concat(transactionHash),
  getExplorerTokenLink: address => "".concat(SepoliaExplorer, "/token/").concat(address),
  matchedEnv: [TokenlonEnv.local, TokenlonEnv.development]
};
export var ArbitrumOne = {
  chainId: 42161,
  chainName: 'Arbitrum',
  nativeSymbol: 'ETH',
  rpcUrl: 'https://arb1.arbitrum.io/rpc',
  logoUrl: LogoArb,
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  lonAddress: '0x55678cd083fcDC2947a0Df635c93C838C89454A3',
  explorer: 'https://arb1.arbitrum.io',
  getExplorerAddressLink: address => "https://arbiscan.io/address/".concat(address),
  getExplorerTransactionLink: transactionHash => "https://arbiscan.io/tx/".concat(transactionHash),
  getExplorerTokenLink: address => "https://arbiscan.io/token/".concat(address),
  matchedEnv: [TokenlonEnv.staging, TokenlonEnv.production],
  pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS
};
export var ArbitrumRinkeby = {
  chainId: 421611,
  chainName: 'Arbitrum Rinkeby',
  nativeSymbol: 'ARETH',
  rpcUrl: 'https://rinkeby.arbitrum.io/rpc',
  // rpcUrl: 'https://arbitrum-testnet.imdev.works',
  logoUrl: LogoArb,
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',
  lonAddress: '0x3828E007B7413Be83550AB520f706cC1773464DB',
  explorer: 'https://testnet.arbitrum.io',
  getExplorerAddressLink: address => "https://testnet.arbiscan.io/address/".concat(address),
  getExplorerTransactionLink: transactionHash => "https://testnet.arbiscan.io/tx/".concat(transactionHash),
  getExplorerTokenLink: address => "https://testnet.arbiscan.io/tx/".concat(address),
  matchedEnv: [TokenlonEnv.local, TokenlonEnv.development],
  pendingTransactionsRetryOptions: DEFAULT_RETRY_OPTIONS
};
export var WCMainnet = {
  chainId: 1,
  rpcUrl: 'https://eth-mainnet.token.im'
};
export var WCSepolia = {
  chainId: 11155111,
  rpcUrl: 'https://sepolia-testnet.imdev.works'
};
export var WCArbitrumOne = {
  chainId: 42161,
  rpcUrl: 'https://arb1.arbitrum.io/rpc'
};
export var WCArbitrumRinkeby = {
  chainId: 421611,
  rpcUrl: 'https://rinkeby.arbitrum.io/rpc'
};
export var BSC = {
  chainId: 56,
  chainName: 'BSC'
};
export var Avalanche = {
  chainId: 43114,
  chainName: 'Avalanche'
};
export var Polygon = {
  chainId: 137,
  chainName: 'Polygon'
};
export var Cronos = {
  chainId: 25,
  chainName: 'Cronos'
};
export var Fantom = {
  chainId: 250,
  chainName: 'Fantom'
};
export var Optimism = {
  chainId: 10,
  chainName: 'Optimism'
};
export var Klaytn = {
  chainId: 8217,
  chainName: 'Klaytn'
};
export var Moonbeam = {
  chainId: 1284,
  chainName: 'Moonbeam'
};
export var Gnosis = {
  chainId: 100,
  chainName: 'Gnosis'
};
export var Aurora = {
  chainId: 1313161554,
  chainName: 'Aurora'
};
export default {
  Mainnet,
  Sepolia,
  ArbitrumOne,
  ArbitrumRinkeby
};